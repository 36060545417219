<template>
    <i class="material-icons" v-if=     "type === 'tour_guide'"           title="Reiseleitung">record_voice_over</i>
    <i class="material-icons" v-else-if=     "type === 'guide'"           title="Reiseleitung">record_voice_over</i>
    <i class="material-icons" v-else-if=     "type === 'workshop'"           title="Workshop">diversity_3</i>
    <i class="material-icons" v-else-if="type === 'boat_trip'"            title="Bootsfahrt">rowing</i>
    <i class="material-icons" v-else-if="type === 'restaurant'"           title="Restaurant">restaurant_menu</i>
    <i class="material-icons" v-else-if="type === 'food_on_board'"        title="Essen an Bord">restaurant_menu</i>
    <i class="material-icons" v-else-if="type === 'entrances_and_fees'"   title="Besichtigungen und Eintritte">monetization_on</i>
    <i class="material-icons" v-else-if="type === 'conservation_program'" title="Unterhaltung">album</i>
    <i class="material-icons" v-else-if="type === 'train_ride'"           title="Züge">directions_railway</i>
    <i class="material-icons" v-else-if="type === 'bus_presentation'"     title="Bus">directions_bus</i>
    <i class="material-icons" v-else-if="type === 'tastings'"             title="Verkostung">local_bar</i>
    <i class="material-icons" v-else-if="type === 'tourist_train'"        title="Touristenzüge und Ausflüge">tour</i>
    <i class="material-icons" v-else-if="type === 'other'"                title="Sonstiges">view_module</i>
    <i class="material-icons" v-else                                      title="Sonstiges">map</i>
</template>



<script>
export default {
    props: {
        type: { type: String, required: true }
    }
}
</script>
