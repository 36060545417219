var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type === "tour_guide"
    ? _c(
        "i",
        { staticClass: "material-icons", attrs: { title: "Reiseleitung" } },
        [_vm._v("record_voice_over")]
      )
    : _vm.type === "guide"
    ? _c(
        "i",
        { staticClass: "material-icons", attrs: { title: "Reiseleitung" } },
        [_vm._v("record_voice_over")]
      )
    : _vm.type === "workshop"
    ? _c("i", { staticClass: "material-icons", attrs: { title: "Workshop" } }, [
        _vm._v("diversity_3"),
      ])
    : _vm.type === "boat_trip"
    ? _c(
        "i",
        { staticClass: "material-icons", attrs: { title: "Bootsfahrt" } },
        [_vm._v("rowing")]
      )
    : _vm.type === "restaurant"
    ? _c(
        "i",
        { staticClass: "material-icons", attrs: { title: "Restaurant" } },
        [_vm._v("restaurant_menu")]
      )
    : _vm.type === "food_on_board"
    ? _c(
        "i",
        { staticClass: "material-icons", attrs: { title: "Essen an Bord" } },
        [_vm._v("restaurant_menu")]
      )
    : _vm.type === "entrances_and_fees"
    ? _c(
        "i",
        {
          staticClass: "material-icons",
          attrs: { title: "Besichtigungen und Eintritte" },
        },
        [_vm._v("monetization_on")]
      )
    : _vm.type === "conservation_program"
    ? _c(
        "i",
        { staticClass: "material-icons", attrs: { title: "Unterhaltung" } },
        [_vm._v("album")]
      )
    : _vm.type === "train_ride"
    ? _c("i", { staticClass: "material-icons", attrs: { title: "Züge" } }, [
        _vm._v("directions_railway"),
      ])
    : _vm.type === "bus_presentation"
    ? _c("i", { staticClass: "material-icons", attrs: { title: "Bus" } }, [
        _vm._v("directions_bus"),
      ])
    : _vm.type === "tastings"
    ? _c(
        "i",
        { staticClass: "material-icons", attrs: { title: "Verkostung" } },
        [_vm._v("local_bar")]
      )
    : _vm.type === "tourist_train"
    ? _c(
        "i",
        {
          staticClass: "material-icons",
          attrs: { title: "Touristenzüge und Ausflüge" },
        },
        [_vm._v("tour")]
      )
    : _vm.type === "other"
    ? _c(
        "i",
        { staticClass: "material-icons", attrs: { title: "Sonstiges" } },
        [_vm._v("view_module")]
      )
    : _c(
        "i",
        { staticClass: "material-icons", attrs: { title: "Sonstiges" } },
        [_vm._v("map")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }