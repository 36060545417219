import weTours from './weTours/EmailBody';
import buspaket from './buspaket/EmailBody';

export default function (organisationId = 1, body = '', phone = '', email = null) {
    if(organisationId === 2){
        return weTours(body, phone, email);
    } else {
        return buspaket(body, phone, email);
    }
}
