<template>
    <div class="container">
        <div class="email-attachments">
            <input class="is-hidden" type="file" ref="fileUpload" v-on:input="addFiles" multiple>
            <button class="button is-gray" v-on:click="$refs.fileUpload.click()" style="margin-bottom: 5px">Anhänge vom Compuer hochladen</button>

            <button
                class="button is-gray"
                style="margin-bottom: 5px"
                v-on:click="isModalVisible = true">Anhänge aus dem Vorgang hochladen</button>

            <template v-if="isModalVisible" >
                    <div class="panel-block"  style="background-color:#F7F9FA">
                        <div class="redesign-table">
                            <div class="table-wrapper">
                                <filters
                                    class="row is-filters"
                                    v-model="documentFilter"
                                    v-bind:options="optionsDocuments"
                                ></filters>
                                <div class="row is-head" >
                                    <div class="cell is-user-cell"><input type="checkbox" @input="toggleAll">
                                    </div>
                                    <div class="cell is-one-third is-sortable">
                                        <i class="fa fa-file" aria-hidden="true"></i>
                                        Dokumentenname
                                    </div>

                                    <div class="cell is-smaller-cell">
                                        <i class="fa fa-file-o" aria-hidden="true"></i>
                                        Dateityp
                                    </div>

                                    <div class="cell is-sortable is-larger-cell">
                                        <i class="material-icons">help</i>
                                        Kategorie
                                    </div>

                                    <div class="cell is-sortable is-days-cell">
                                        <i class="material-icons">date_range</i>
                                        Erstellungsdatum
                                    </div>

                                    <div class="cell is-actions">
                                        <i class="material-icons">search</i>
                                    </div>
                                </div>

                                <loading v-if="isLoading.documents"></loading>
                                <template v-else>
                                    <div v-if="documents.length === 0" class="is-empty">Es gibt keine Dokumente</div>
                                    <div v-else class="is-zebra">
                                        <div
                                            v-for="document in documents"
                                            v-bind:key="document.id"
                                            class="row"
                                        >

                                            <div class="cell is-user-cell">
                                                <input type="checkbox" v-model="checkedDocuments" v-bind:value="document">
                                            </div>
                                            <div class="cell is-one-third">
                                                {{ document.name }}
                                            </div>
                                            <div class="cell is-smaller-cell">
                                                {{ fileType(document) }}
                                            </div>
                                            <div class="cell is-larger-cell">
                                                <div class="tags">
                                                    <span class="tag is-white" v-if="document.type">
                                                        {{ getValueWithKey({ key: document.type, optionList: optionsDocuments.types }) }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="cell is-days-cell">
                                                {{ document.createdAt | fullYearWithTime }}
                                            </div>
                                            <div class="cell is-actions">
                                                <button
                                                    v-if="previewable(document)"
                                                    title="Vorschau"
                                                    v-on:click="previewDocument(document)"
                                                ><i class="material-icons">search</i></button>
                                            </div>
                                        </div>
                                    </div>

                                </template>

                            </div>
                        </div>
                    </div>

                    <footer style="display: block">
                        <div class="level">
                            <button class="button is-cancel" v-on:click="isModalVisible = false">Abbrechen</button>
                            <button class="button is-primary" v-on:click="addAttachments">Hinzufügen</button>
                        </div>
                    </footer>
            </template>
            <div style="display: flex; flex-direction: column;align-items: flex-start;">
                <template
                     v-for="(file, index) in localAttachments"
                >
                    <div class="level level-left mb-1">
                        <div
                            class="button level-item"
                            v-on:click="previewDocument(file)"
                            v-bind:title="file.name"
                        >
                            <i aria-hidden="true" class="fa fa-file"></i> {{ file.name }}
                        </div>
                        <button class="btn-delete level-item" title="Löschen" v-on:click="localAttachments.splice(index, 1)"><i class="material-icons">delete</i></button>
                    </div>

                </template>
            </div>

        </div>
        <preview  v-model="previewURL" />
    </div>

</template>



<script>

import moment from 'moment';
import Loading from '@components/Loading';
import {getAxios, getDocuments} from '@api';
import { notifyError } from '@components/Notification';
import Preview       from '@components/documents/Preview';
import {getValueWithKey, fullYearWithTime, buildURLQuery} from '@utilities/functions';
import Filters from "@components/CRUD/Table/Filters.vue";

const {optionsDocuments} = require("@api");

export default {
    props: {
        ordersSelected: {},
        value: {}
    },


    data: function () {
        return {
            documentFilter: {
                _search: {
                    value: '',
                },
                type: [],
                'links.order.id': this.ordersSelected.map(order => order.id),
            },
            documents: [],
            previewURL: null,
            isModalVisible: false,
            isLoading: {
                documents: false,
                input: false
            },
            checkedDocuments: [],
            optionsDocuments: {
                types: []
            },
            allToggled: false,
        }
    },


    methods: {
        toggleAll () {
            if(this.allToggled) {
                this.checkedDocuments = [];
            } else {
                this.checkedDocuments = this.documents;
            }
            this.allToggled = !this.allToggled;
        },

        previewable: function(document) {
            return ['.docx', '.pdf', '.doc', '.xls' , '.xlsx'].includes(this.fileType(document))
        },

        getValueWithKey,
        previewDocument: function(document) {
            if(document.id) {
                this.previewURL = document;
            }
        },
        addFiles: function (event) {
            this.localAttachments.push(...[...event.target.files]);
        },
        formatDate: function(date) {
            return date ? moment(date, 'DD.MM.YYYY hh:mm:ss').format('DD.MM.YYYY hh:mm') : '';
        },

        fileType: function (document) {
            return document.path ? `.${document.path.split('.').pop()}` : '';
        },

        addAttachments: function () {
            this.isModalVisible = false;
            this.localAttachments =  [...this.localAttachments, ...this.checkedDocuments];
            this.checkedDocuments = [];
        },

        loadDocuments: function () {
            this.isLoading.documents = true;
            let filter = {}
            for (const key in this.documentFilter) {
                if(key === '_search') {
                    filter[key] = this.documentFilter[key].value
                } else {
                    filter[key] = this.documentFilter[key];
                }
            }
            getAxios('documents', filter).then(response => {
                this.documents = response.data;
            }, error => { notifyError('Couldn\'t retrieve concepts! Server Error!'); })
                .then(() => { this.isLoading.documents = false; });
        },

    },
    filters: {
        fullYearWithTime,

    },
    computed: {
        localAttachments: {
            get () {
                return this.value;
            },
            set (value) {
                this.$emit('input', value);
            }
        }
    },
    watch: {
        documentFilter: {
            deep: true,
            handler: function () {
                this.loadDocuments()
            }
        },

        ordersSelected: function () {
            this.documentFilter['links.order.id'] = this.ordersSelected.map(order => order.id);
        },

        isModalVisible: function () {
            if (this.isModalVisible) {
                this.loadDocuments();
            }
        }
    },

    created: function () {
        optionsDocuments()
            .then(response => {
                this.optionsDocuments = response.data;
                this.$emit('options:loaded', response.data)

            }, error => { notifyError('Couldn\'t load Options! Server Error!'); });

    },



    components: {
        Filters,
        Preview,
        Loading
    }
}
</script>
