import Vuex from 'vuex';
import {getAxios, getOrders, getRequestsByOrder, getUsers} from "@api";
import {notifyError} from "@components/Notification";
import get from "lodash/get";
import axios from "axios";
import uniq from "lodash/uniq";
import {currentUser, getIDFromString} from "@utilities/functions";
import emailBody from "@clientSpecific/email/template/EmailBody";
import {settings} from "@clientSpecific/utilities/settings";
const orderQueryString = '_groups[]=order_list_read&_groups[]=order_read_extended_contacts&_groups[]=contact_read&_groups[]=contact_read_relations&_groups[]=provider_read_list&_groups[]=agency_read_list';


const isDirection = function (email, state, direction) {
    for (let i = 0, len = state.types.length; i < len; i++) {
        if (email.folder === state.types[i].type) {
            return state.types[i].direction === direction;
        }
    }

    return false;
};

const getRequestSortHelper = function (request) {
    if (!!request.placeholder && typeof request.placeholder === 'string') {
        const id = request.placeholder.split('/').pop();

        if (request.requestType ==='hotel')  {
            return `hotel-${id}`;
        } else if (request.requestType ==='ferry') {
            return `ferry-${id}`
        } else if (request.requestType ==='train') {
            return `train-${id}`
        } else if (request.requestType ==='airline')  {
            return `airline-${id}`
        } else {
            return `other-${id}`
        }
    } else {
        return false
    }
}


export default new Vuex.Store({
    state: {
        //For live emails
        types: [
            /*{ title: 'Neue Emails',          type: 'inbox',                  direction: 'in'  },
            { title: 'Gesendet',             type: 'sent',                   direction: 'out' },
            // { title: 'Papierkorb',        type: 'trash' },

            { title: 'Wichtig',              type: 'travelize.important',    direction: 'in'  },
            { title: 'Sonstige Emails',      type: 'travelize.other',        direction: 'in'  },
            { title: 'Erledigt',             type: 'travelize.archive',      direction: 'in'  },
            { title: 'Postausgang Erledigt', type: 'travelize.archive_sent', direction: 'out' },
            // { title: 'Papierkorb',        type: 'travelize.trash' }*/
            { title: 'Posteingang',        type: 'INBOX',          direction: 'in'  },
            { title: 'Postausgang',        type: 'Drafts',         direction: 'in'  },
            { title: 'Gesendete Elemente', type: 'Sent Items',     direction: 'in'  },
            { title: 'Papierkorb',         type: 'Deleted Items',  direction: 'in'  },
            { title: 'Öffentliche Ordner', type: 'Public Folders', direction: 'in'  },
        ],
        emailsServer:   [],
        emailsLive:     [],
        emailsSelected: {
            direction: 'in',
            in:  [],
            out: []
        },
        updateFolder:  false,
        loadingFolder: false,
        isLoading: false,


        //index.php ->orderSelect and provider/client/request select
        emailType: 'client', //Email an Kunde oder Leistungsträger
        orders: [], //order options for multiselect
        ordersSelected: [], //selected order in multiselect
        isLoadingOrders: false,


        providers: [], //all providers of the selected orders (options) including all requests
        providerSelected: null, //if email goes to provider or agency,
        isLoadingProviders: false,
        requestsSelected: [], //selectedRequests for provider or agency

        //for orderConcepts (procurement)
        orderConceptsSelected: [],
        conceptProvidersSelected: [],
        priceRequestYear: null,

        action:        null, //new, reply, forward

        //Form
        users: [],
        contacts: [],
        templates: {
            provider: {
                de_DE: {
                    formal: [],
                    informal: [],
                },
                fr: {
                    formal: [],
                    informal: [],
                },
                en: {
                    formal: [],
                    informal: [],
                },
            },
            client: {
                de_DE: {
                    formal: [],
                    informal: [],
                },
                fr: {
                    formal: [],
                    informal: [],
                },
                en: {
                    formal: [],
                    informal: [],
                },
            },
        },
        settings: settings,
        isPhone: false,
        templateSelected: null,
        isFormal: true,
        localeSelected: 'de_DE',
        variantSelected: null,
        newEmail: {
            fromEmail: null,
            toAddresses: [],
            ccAddresses: [],
            bccAddresses: [],
            subject: null,
            textHtml: '',
            folder: 'sent',
            todoDate: null,
            _files: []
        },
        cancelSource: null,
    },


    getters: {
        emailsListIn: function (state) {
            return [
                ...state.emailsLive.filter(email   => isDirection(email, state, 'in')),
                ...state.emailsServer.filter(email => isDirection(email, state, 'in'))
            ];
        },


        emailsListOut: function (state) {
            return [
                ...state.emailsLive.filter(email   => isDirection(email, state, 'out')),
                ...state.emailsServer.filter(email => isDirection(email, state, 'out'))
            ];
        },

        emailsListByType: function (state, getters) {
            let list = {
                    in: {},
                    out: {}
                };

            state.types.forEach(type => {
                if (type.direction === 'in') {
                    list.in[type.type]  = getters.emailsListIn.filter(email  => email.folder === type.type);
                } else {
                    list.out[type.type] = getters.emailsListOut.filter(email => email.folder === type.type);
                }
            });

            return list;
        },


        emailsSelected: function (state) {
            return state.emailsSelected[state.emailsSelected.direction];
        },


        email: function (state) {
            // In order to pass arguments to getters we need to return a function
            return ({ id, folder }) => {
                for (let i = 0, len = state.emailsLive.length; i < len; i++) {
                    if (state.emailsLive[i].uid === id && state.emailsLive[i].folder === folder) {
                        return state.emailsLive[i];
                    }
                }
                for (let i = 0, len = state.emailsServer.length; i < len; i++) {
                    if (state.emailsServer[i].id === id && state.emailsServer[i].folder === folder) {
                        return state.emailsServer[i];
                    }
                }

                return null;
            };
        },


        isLive: function (state) {
            return (email) => !email.id;
        },


        isDirection: function (state) {
            return (email, direction) => isDirection(email, state, direction);
        }
    },


    mutations: {
        updateEmailsServer: function (state, list) { state.emailsServer = list; },
        updateEmailsLive:   function (state, list) { state.emailsLive   = list; },


        updateFolder:  function (state, shouldUpdate) { state.updateFolder  = shouldUpdate; },
        loadingFolder: function (state, isLoading)    { state.loadingFolder = isLoading; },


        updateEmailsSelected: function (state, object) {
            if (object.event && object.event.shiftKey) {
                // Multi email select
                const index = state.emailsSelected[state.emailsSelected.direction].findIndex(email =>
                    (object.email.id ? email.id === object.email.id : email.uid === object.email.uid));

                if (index !== -1) {
                    // Deselecting one
                    if (state.emailsSelected[state.emailsSelected.direction].length > 1) {
                        state.emailsSelected[state.emailsSelected.direction].splice(index, 1);
                    }

                } else {
                    // Adding multiselect - at the begging of the array
                    state.emailsSelected[state.emailsSelected.direction].unshift(object.email);
                }

            } else {
                // Simple click
                state.emailsSelected[state.emailsSelected.direction] = [object.email];
            }
        },


        // When we change the folder we need to empty the list and select the email given (the first email if any)
        updateChangeFolder: function (state, object) {
            state.emailsSelected[state.emailsSelected.direction] = object;
        },


        updateDirection: function (state, direction) {
            state.emailsSelected.direction = direction;
        },


        movedFromLive: function (state, emails) {
            // Updating the emailsSelected list
            const indexSelected = state.emailsSelected[state.emailsSelected.direction].findIndex(email => email.uid    === emails.old.uid &&
                                                                                                          email.folder === emails.old.folder);
            if (indexSelected !== -1) {
                state.emailsSelected[state.emailsSelected.direction].splice(indexSelected, 1, emails.new);
            }

            // Deleting the old
            const indexLive = state.emailsLive.findIndex(email => email.uid    === emails.old.uid &&
                                                                  email.folder === emails.old.folder);

            if (indexLive !== -1) {
                state.emailsLive.splice(indexLive, 1);
            }

            // Adding the new
            state.emailsServer.unshift(emails.new);
        },


        updateEmailOnServer: function (state, { id, folder, links, tags }) {
            const index = state.emailsServer.findIndex(email => email.id === id);
            state.emailsServer[index].folder = folder;
            state.emailsServer[index].links  = links;
            state.emailsServer[index].tags   = tags;
        },


        /*
            ==================== INTERNALS - For in VUEX store use ONLY ====================
        */
        internalInitialEmailsSelected: function (state, object) {
            state.emailsSelected[object.direction] = [object.email];
        },


        internalDeleteEmailsSelected: function (state) {
            let index = -1;

            state.emailsSelected[state.emailsSelected.direction].forEach(email => {
                if (email.id) {
                    index = state.emailsServer.findIndex(emailServer => email.id === emailServer.id);
                    if (index !== -1) {
                        state.emailsServer.splice(index, 1);
                    }

                } else {
                    index = state.emailsLive.findIndex(emailLive => email.uid === emailLive.uid && email.folder === emailLive.folder);
                    if (index !== -1) {
                        state.emailsLive.splice(index, 1);
                    }
                }
            });
            state.emailsSelected[state.emailsSelected.direction] = [];
        },
        /* ================================ End of INTERNALS ================================ */


        updateOrders:  function (state, newOrders)  { state.orders  = newOrders; },

        updateClients: function (state, newClients) { state.clients = newClients; },
        updateUsers:   function (state, newUsers)   { state.users   = newUsers; },


        updateAction:  function (state, newAction) {
            if (state.emailsSelected[state.emailsSelected.direction].length > 0) {
                state.action = newAction;

            } else {
                state.action = null;
            }
        },
        updateTemplates: function (state, newTemplates) { state.templates = newTemplates; },
        updateLoading: function (state, newState) { state.isLoading = newState; },

        updateProviderSelected: function (state, provider) { state.providerSelected = provider; },

        updateOrderLoading: function(state, value) {state.isLoadingOrders = value; },

        cancelSearch : function (state) {
            if (state.cancelSource) {
                state.cancelSource.cancel('Axios canceled');
            }
        },

        updateContacts: function(state) {
            state.contacts = [];
            if(state.emailType === 'provider' && state.providerSelected) {
                state.contacts = JSON.parse(JSON.stringify(state.providerSelected.provider.contacts));
            } else if(state.ordersSelected.length > 0 && state.ordersSelected[0].client.contacts && state.ordersSelected[0].client.contacts.length > 0 && state.ordersSelected[0].client.contacts[0].id) {
                state.contacts =  JSON.parse(JSON.stringify(state.ordersSelected[0].client.contacts.filter(contact => contact.email || state.isPhone)));
            } else if(state.conceptProvidersSelected.length > 0 && state.conceptProvidersSelected[0].contacts) {
                state.contacts = JSON.parse(JSON.stringify(state.conceptProvidersSelected[0].contacts));
            }
            let usersArray = state.users.filter(item => item.userEmails.length > 0).map(item => {

                let email = null;
                if(state.ordersSelected && state.ordersSelected[0]) {
                    email = item.userEmails.find(userEmail => userEmail.organisation.id === state.ordersSelected[0].organisationFilter.id);
                }
                if(!email) {
                    email = item.userEmails[0];
                }
                return {
                    ...item,
                    email: email.email,
                }
            });
            if(typeof state.contacts === 'object') {
                state.contacts = Object.values(state.contacts);
            }
            state.contacts.push(...usersArray);


        },

        addToProviders: function (state, request) {
            let provider    = null;
            let isAgency = false;
            let requestType = request.requestType ? request.requestType : 'agency';

            if(request.agency) {
                provider = request.agency;
                isAgency = true;
            } else if (request.requestType === 'hotel') {
                provider    = request.hotel;
            } else if (request.requestType === 'ferry') {
                provider    = request.ferry;
            } else if (request.requestType === 'airline') {
                provider    = request.airline;
            } else if(request.provider === 'train') {
                provider    = request.train;
            } else {
                provider    = request.provider;
            }

            const index = state.providers.findIndex(providerItem => isAgency === providerItem.isAgency && providerItem.provider.id === provider.id);

            if (index !== -1) {
                // We found it. We just need to add the request to its list
                let requestIndex = state.providers[index].requests.findIndex(item => item.id === request.id);
                if(requestIndex === -1) {
                    state.providers[index].requests.push(request);
                }
            } else {
                // No provider found. We add the provider with its request
                state.providers.push({
                    feID:     requestType + '-' + provider.id,
                    provider: provider,
                    isAgency: isAgency,
                    requests: [request]
                });
            }
        },
    },


    actions: {
        getUsers: function (context) {
            return new Promise(resolve => {
                if(context.state.users.length === 0) {
                    getUsers().then(response => {
                        context.commit('updateUsers', response.data);
                        resolve(response.data);
                    });
                } else {
                    resolve(context.state.users);
                }
            });
        },

        getTemplates: function (context) {
            return new Promise(resolve => {
                let formal = context.state.isFormal ? 'formal' : 'informal';
                if(context.state.templates[context.state.emailType][context.state.localeSelected][formal] && context.state.templates[context.state.emailType][context.state.localeSelected][formal].length > 0) {
                    resolve(context.state.templates[context.state.emailType][context.state.localeSelected][formal]);
                } else {
                    getAxios('email_templates', {
                        isFormal: context.state.isFormal,
                        category: context.state.emailType,
                        locale: context.state.localeSelected,
                        _groups: ['email_template:email_variant', 'email_variant']
                    }).then(response => {
                        context.state.templates[context.state.emailType][context.state.localeSelected][formal] = response.data;
                        resolve(context.state.templates[context.state.emailType][context.state.localeSelected][formal]);
                    })
                }
            })
        },

        resetTemplates: function(context, templateToSelect) {
            if(context.state.isPhone) {
                return;
            }


            context.dispatch('getTemplates').then(response => {
                let standardTemplates = response;

                if(context.state.templateSelected && context.state.variantSelected) {
                    context.state.templateSelected = standardTemplates.find(
                        template => template.name === context.state.templateSelected.name
                    )
                    if(context.state.templateSelected ) {
                        context.state.variantSelected = context.state.templateSelected.variants.find(variant => variant.name === context.state.variantSelected.name)
                        if(context.state.variantSelected) {
                            context.dispatch('fillEmailBody');
                            return;
                        }
                    }
                }

                if(!templateToSelect) {
                    templateToSelect = 'client';
                    if(context.state.emailType === 'provider' && context.state.providerSelected && context.state.providerSelected.requests.length > 0) {
                        templateToSelect = context.state.providerSelected.requests[0].requestType;
                    }
                }
                if(templateToSelect) {
                    let found = standardTemplates.filter(template => template.defaultArray.includes(templateToSelect));
                    standardTemplates = found.length > 0 ? found : standardTemplates;
                }

                //select template and variant
                if(standardTemplates.length > 0) {
                    context.state.templateSelected = standardTemplates[0];
                    let variant = context.state.templateSelected.variants.find(item => item.variantStandard);
                    if(context.state.templateSelected.variants.length > 0) {
                        context.state.variantSelected = variant ? variant : context.state.templateSelected.variants[0];
                        context.dispatch('fillEmailBody');
                    }
                }
            });
        },

        fillEmailBody: function(context) {
            if(context.state.isPhone) {
                return;
            }
            if (context.state.variantSelected) {
                let textHtml = '';

                let textPhone = '';
                if(currentUser('phoneDirect')){
                    textPhone = '(Dw. ' + currentUser('phoneDirect') + ')';
                }

                let action = context.state.action === 'reply' ||
                    context.state.action === 'replyAll' ||
                    context.state.action === 'forward';


                let organisationId = context.state.newEmail.fromEmail ? parseInt(context.state.newEmail.fromEmail.organisation.replace('/api/organisations/', '')) : 1;

                if (action) {
                    //Only change body
                    textHtml = emailBody(organisationId, context.state.variantSelected.content, textPhone, context.getters.emailsSelected[0]);
                } else {
                    context.state.newEmail.subject = context.state.variantSelected.subject ? context.state.variantSelected.subject : context.state.newEmail.subject;
                    textHtml = emailBody(organisationId, context.state.variantSelected ? context.state.variantSelected.content : '', textPhone);
                }
                context.state.newEmail.textHtml = textHtml;
            }

            context.dispatch('renderTemplate');
        },

        renderTemplate: function(context) {


            let apiQuery = '?',
                ids      = [];

            if (context.state.newEmail.links) {
                // Getting the ids
                ids = context.state.newEmail.links.map(link => link.order ? link.order.id : null);
                // Removing the nulls
                ids = ids.filter(id => id !== null);
                // Only unique (as we may have multiple links from the same order)
                ids = uniq(ids);

                if (ids.length > 0) {
                    apiQuery += 'orders[]=' + ids.join('&orders[]=');
                }

            } else if (context.state.ordersSelected.length > 0) {
                apiQuery += 'orders[]=' + context.state.ordersSelected.map(order => order.id).join('&orders[]=');
            }

            if(context.state.orderConceptsSelected.length > 0) {
                apiQuery += 'orderConcepts[]=' + context.state.orderConceptsSelected.map(order => order.id).join('&orderConcepts[]=');
            }

            if(context.state.newEmail.toAddresses.length > 0) {
                apiQuery += '&';
                apiQuery += context.state.newEmail.toAddresses.map(contact => contact.id ? 'toContacts[]=' + contact.id : 'toContactsEmail[]=' + contact.email ).join('&');
            }

            if(context.state.newEmail.bccAddresses.length > 0) {
                apiQuery += '&';
                apiQuery += context.state.newEmail.bccAddresses.map(contact => contact.id ? 'bccContacts[]=' + contact.id : '').join('&');
            }

            if(context.state.newEmail.ccAddresses.length > 0) {
                apiQuery += '&';
                apiQuery += context.state.newEmail.ccAddresses.map(contact => contact.id ? 'ccContacts[]=' + contact.id : '').join('&');
            }

            if(context.state.requestsSelected.length > 0) {
                apiQuery += '&';
                apiQuery += context.state.requestsSelected.map(request => 'requests[]=' + request.id).join('&');
            }

            if(context.state.conceptProvidersSelected.length > 0) {
                apiQuery += '&';
                apiQuery += context.state.conceptProvidersSelected.map(request => 'conceptProviders[]=' + request.id).join('&');
            }



            context.state.isLoadingPreview = true;

            if(context.state.newEmail.textHtml) {
                axios.post(
                    '/api/email_preview' + apiQuery,
                    {
                        content: context.state.newEmail.textHtml,
                        subject: context.state.newEmail.subject
                    },
                    {
                        headers: {'X-LOCALE': context.state.localeSelected}
                    }
                ).then(response => {
                    context.state.newEmail.textHtml = response.data.content;
                    context.state.newEmail.subject  = response.data.subject;
                    context.state.isLoadingPreview = false;

                }, error => {
                    context.state.isLoadingPreview = false;
                    notifyError(error.response.data + '! Server Error!');
                });
            }

        },

        resetToContacts: function(context, {contactToSelect = null, selectContact = true, contactsToSelect = []}) {
            //toContacts
            return new Promise(resolve => {
                context.dispatch('getUsers').then(response => {
                    context.commit('updateContacts');
                    if(selectContact) {
                        if(contactToSelect && contactToSelect.email) {
                            context.state.newEmail.toAddresses = [contactToSelect];
                        } else if(contactsToSelect && contactsToSelect.length > 0) {
                            context.state.newEmail.toAddresses = contactsToSelect;
                        } else if(context.state.emailType === 'provider' && context.state.requestsSelected.length > 0) {
                            if(context.state.requestsSelected[0].requestContact) {
                                context.state.newEmail.toAddresses = [context.state.requestsSelected[0].requestContact];
                            }
                        } else {
                            context.state.newEmail.toAddresses = [context.state.contacts[0]];
                        }
                    }
                    resolve(context.state.newEmail.toAddresses);
                });
            })

        },

        resetPhoneForm: function(context) {
            context.state.newEmail.textHtml = '';
            context.state.newEmail.subject = '';
            if(context.state.action === 'edit') {
                context.state.newEmail = context.getters.emailsSelected[0];
                context.dispatch('resetToContacts', {contactToSelect: null, selectContact: false}).then(response => {
                    if(context.getters.emailsSelected[0].contact.emailInfos) {
                        context.state.newEmail.contact = context.state.contacts.find(item => item.fullName === context.getters.emailsSelected[0].contact.emailInfos[2])
                    }
                })
            }
        },

        handleReply: function(context) {
            let selectedEmail = JSON.parse(JSON.stringify(context.getters.emailsSelected[0]));
            context.state.newEmail.subject = (this.action === 'forward' ? 'FW: ' : 'RE: ') + selectedEmail.subject;
            if (context.state.action === 'replyAll' ) {
                let toAddresses = [
                    {
                        email: selectedEmail.fromAddress,
                        firstName: selectedEmail.fromName,
                        lastName: null,
                    }
                ];
                selectedEmail.toAddresses.map(item => {
                    toAddresses.push({
                        email:     item.email,
                        firstName: item.name,
                        lastName:  null,
                        ...selectedEmail.contact && selectedEmail.contact.emailInfos && selectedEmail.contact.emailInfos[3] && {
                            id: selectedEmail.contact.emailInfos[3] }
                    })
                });
                context.state.newEmail.toAddresses = toAddresses.filter(address => address.email !== context.state.newEmail.fromEmail.email);
            } else if (context.state.action === 'reply'){
                context.state.newEmail.toAddresses = [{
                    email:     selectedEmail.fromAddress,
                    firstName: selectedEmail.fromName,
                    lastName:  null,
                    ...selectedEmail.contact && selectedEmail.contact.emailInfos && selectedEmail.contact.emailInfos[3] && {
                        id: selectedEmail.contact.emailInfos[3]
                    }
                }];
            }
            context.state.newEmail.links       = selectedEmail.links;
            if(context.state.action === 'forward') {
                if (selectedEmail.attachments) {
                    context.state.newEmail._files = selectedEmail.attachments.filter(item => item.disposition === 'attachment').map(item => ({
                        ...item,
                        name: item.fileName ? item.fileName : item.filename,
                    }));
                }
            }


        },

        resetEmailForm: function(context, {templateToSelect, contactToSelect, documentToAttach}) {
            //From
            if(context.state.ordersSelected && context.state.ordersSelected.length > 0) {
                let userEmail = currentUser('userEmails').find(email => email.organisation === '/api/organisations/' + context.state.ordersSelected[0].organisationFilter.id);
                context.state.newEmail.fromEmail = userEmail;
                if(currentUser('settings') && currentUser('settings')['fillBCC']) {
                    context.state.newEmail.bccAddresses.push(userEmail)
                }
            }


            let action = context.state.action === 'reply' ||
                context.state.action === 'replyAll' ||
                context.state.action === 'forward';

            //attachments
            context.state.newEmail._files = [];
            if(documentToAttach) {
                context.state.newEmail._files = [documentToAttach];
            }
            if(action) {
                context.dispatch('handleReply');
                if(context.state.newEmail.toAddresses.length === 1) {
                    context.dispatch('resetToContacts', {contactToSelect: context.state.newEmail.toAddresses[0]});
                } else {
                    context.dispatch('resetToContacts', {contactsToSelect: context.state.newEmail.toAddresses});
                }
            } else {
                context.dispatch('resetToContacts', {contactToSelect: contactToSelect});
            }

            //get and select templates and render
            context.dispatch('resetTemplates',templateToSelect);
        },

        callCancelRequest : function (context) {
            context.commit('cancelSearch');
            context.state.cancelSource = axios.CancelToken.source();
        },

        orderSearch: function (context, query) {
            if (query) {
                let queryString = '?_search=' + query;

                // For getting basic info about the order (which by default is all closed)
                queryString += '&' + orderQueryString;

                context.state.isLoadingOrders = true;

                context.dispatch('callCancelRequest');
                let cancelToken = context.state.cancelSource.token;

                getOrders(queryString, this.params, cancelToken).then(response => {
                    context.state.orders = response.data;
                }, error => {
                    if(!axios.isCancel(error)) {
                        notifyError('Die Reisen konnten nicht geladen werden! Server error!');
                    }
                })
                    .then(() => { context.state.isLoadingOrders = false; });
            }
        },

        getRequests: function (context) {
            let calls = [];

            context.state.isLoadingProviders = true;

            context.state.ordersSelected.forEach(order => {
                calls.push(getRequestsByOrder(order.id, '&_groups[]=order_list_read&_groups[]=provider_read&_groups[]=agency_read&_groups[]=order_request_contingent_read&_groups[]=order_contingent_item_read&_groups[]=order_request_deadline_read&_groups[]=order_request_status_read&_groups[]=other_service_type_read&_groups[]=contact_read'));
            });

            Promise.all(calls).then(arrays => {
                arrays.forEach((responses, index) => {
                    [].concat(...responses.map(response => response.data)).forEach(request => {
                        // Adding the order so we can group them by request later on
                        request.orderID = context.state.ordersSelected[index].id;

                        const requestSortHelper = getRequestSortHelper(request);
                        if (requestSortHelper) {
                            request._sort = (get(context.state.ordersSelected, [index, 'sortHelper'], []) || [])
                                .findIndex(sortHelper => sortHelper === requestSortHelper);
                        }

                        context.commit('addToProviders', request);

                    });
                });

//                this.setPreselectedClient();

            }, error => { notifyError('Die Provider konnten nicht geladen werden! Server Error!'); })
                .then(() => { context.state.isLoadingProviders = false; });
        },

        preselectOrderConcepts: function (context, {preselectedOrderConcept, preselectedConceptProvider = null}) {
            let orders = [];
           if(preselectedOrderConcept){
                context.state.orderConceptsSelected = Array.isArray(preselectedOrderConcept) ? preselectedOrderConcept : [preselectedOrderConcept];
            }
            return context.dispatch('processPreselectedConceptProvider', {preselectedConceptProvider})
        },

        preselectOrders: function (context, {preselectedOrder, preselectedRequest = null}) {
            let action = context.state.action === 'reply' ||
                context.state.action === 'replyAll' ||
                context.state.action === 'forward' ||
                context.state.action === 'edit';
            let orders = [];
            let emailSelected = context.state.emailsSelected[context.state.emailsSelected.direction][0];
            if(emailSelected && action) {
                orders = emailSelected.links.map(link => link.order ? link.order.id : null);
                // Removing the nulls
                orders = orders.filter(id => id !== null);
                if(preselectedOrder) {
                    orders = orders.map(order => order === preselectedOrder.id ? preselectedOrder : order).sort((a,b) => {
                        if(a.id ) {
                            return -1;
                        }
                        if(b.id) {
                            return 1
                        }
                        return 0;
                    })
                }
                // Only unique (as we may have multiple links from the same order)
                orders = uniq(orders);
            } else if(preselectedOrder){
                orders = Array.isArray(preselectedOrder) ? preselectedOrder : [preselectedOrder];
            }
            return context.dispatch('processPreselectedOrders', {orders, preselectedRequest, preselectedOrder})
        },

        getOrders: function (context, orders) {
            return new Promise((resolve, reject) => {
                let orderIds = [];
                let ordersWithDetails = [];

                orders.map(order => {
                    if(!order.id) {
                        orderIds.push(order);
                    }  else {
                        ordersWithDetails.push(order);
                    }
                })
                if (orderIds.length > 0) {
                    context.commit('updateOrderLoading', true);
                    getOrders('?' + orderQueryString, {
                        'id': orderIds
                    }).then(response => {
                        ordersWithDetails.push(...response.data);
                        resolve(ordersWithDetails);
                    })
                } else {
                    resolve(ordersWithDetails);
                }
            });
        },


        processPreselectedOrders: function(context, {orders, preselectedRequest, preselectedOrder}) {
            return new Promise((resolve, reject) => {
                context.dispatch('getOrders', orders).then(response => {
                    context.commit('updateOrders', response);
                    context.state.ordersSelected = response;
                    context.commit('updateOrderLoading', false);
                    context.dispatch('processPreselectedRequest', {preselectedRequest, preselectedOrder}).then(response => {
                        resolve('orders and requests loaded');
                    });
                });
            })
        },

        getPreselectedConceptProvider: function(context, {preselectedConceptProvider}) {
            return new Promise((resolve, reject) => {
                let action = context.state.action === 'reply' ||
                    context.state.action === 'replyAll' ||
                    context.state.action === 'forward';
                let emailSelected = context.state.emailsSelected[context.state.emailsSelected.direction][0];
                if (preselectedConceptProvider) {
                    //If preselectedConcept already exists, only get contacts
                    let providerFilter = preselectedConceptProvider.agency ? 'agency.id' : 'provider.id';
                    getAxios('contacts', {
                        ...preselectedConceptProvider.agency && {'agency.id': preselectedConceptProvider.agency.id},
                        ...!preselectedConceptProvider.agency && {'provider.id': preselectedConceptProvider.provider.id},
                    }).then(response => {
                        preselectedConceptProvider = {
                            ...preselectedConceptProvider,
                            contacts: response.data
                        }
                        resolve([preselectedConceptProvider]);
                    })
                } else if(action && emailSelected && emailSelected.contact && emailSelected.contact.emailInfos) {
                    //if email link, find conceptProviders with contacts directly
                    let splitProviderApi = emailSelected.contact.emailInfos[0].split('/');
                    if(splitProviderApi[1] === 'clients') {
                        resolve(null);
                    } else if(context.state.orderConceptsSelected.length > 0) {
                        let providerFilter = splitProviderApi[1] === 'agencies' ? 'agency.id' : 'provider.id';
                        getAxios('concept_providers', {
                            '_placeholder.day.orderConcepts.id': context.state.orderConceptsSelected[0].id,
                            [providerFilter]: splitProviderApi[2],
                            '_groups': ['contact_read', 'provider_read', 'concept_day_read']
                        }).then(response => {
                            resolve(
                                response.data.map(conceptProvider => ({
                                    ...conceptProvider,
                                    ...conceptProvider.agency && {contacts: conceptProvider.agency.contacts},
                                    ...conceptProvider.provider && {contacts: conceptProvider.provider.contacts},
                                })),
                            )
                        })
                    }
                } else {
                    resolve(null);
                }
            });
        },

        getPreselectedRequest: function(context, {preselectedRequest, preselectedOrder}) {
            return new Promise((resolve, reject) => {
                let action = context.state.action === 'reply' ||
                    context.state.action === 'replyAll' ||
                    context.state.action === 'forward';
                let emailSelected = context.state.emailsSelected[context.state.emailsSelected.direction][0];
                if (preselectedRequest) {
                    resolve([preselectedRequest]);
                } else if(action && emailSelected && emailSelected.contact && emailSelected.contact.emailInfos) {
                    let splitProviderApi = emailSelected.contact.emailInfos[0].split('/');
                    if(splitProviderApi[1] === 'clients') {
                        resolve(null);
                    } else {
                        let providerFilter = splitProviderApi[1] === 'agencies' ? 'agency' : 'provider.id';
                        getAxios('requests', {
                            'request.placeholder.day.order.id': preselectedOrder.id,
                            [providerFilter]: splitProviderApi[2],
                            '_groups': ['trip_dashboard_read', 'contact_read', 'provider_read', 'order_request_read']
                        }).then(response => {
                            resolve(response.data);
                        })
                    }
                } else {
                    resolve(null);
                }
            });
        },

        processPreselectedConceptProvider: function(context, {preselectedConceptProvider}) {
            return new Promise(resolve => {
                context.dispatch('getPreselectedConceptProvider', {preselectedConceptProvider}).then(response => {
                    if (response && response.length > 0) {
                        context.state.conceptProvidersSelected = response;

                        let action = context.state.action === 'reply' ||
                            context.state.action === 'replyAll' ||
                            context.state.action === 'forward' ||
                            context.state.action === 'edit';
                        context.dispatch('resetToContacts', {contactToSelect: null, selectContact: !action});
                        resolve('provider');
                    }
                })
            });
        },

        processPreselectedRequest: function(context, {preselectedRequest, preselectedOrder}) {
            return new Promise(resolve => {
                context.dispatch('getPreselectedRequest', {preselectedRequest, preselectedOrder}).then(response => {
                    if (response && response.length > 0) {
                        context.commit('updateProviderSelected', {
                            provider: response[0].agency ? response[0].agency :
                                response[0].hotel ? response[0].hotel :
                                    response[0].ferry ? response[0].ferry :
                                        response[0].train ? response[0].train :
                                            response[0].otherService.provider,
                            requests: response
                        });
                        let action = context.state.action === 'reply' ||
                            context.state.action === 'replyAll' ||
                            context.state.action === 'forward' ||
                            context.state.action === 'edit';
                        context.dispatch('resetToContacts', {contactToSelect: null, selectContact: !action});
                        context.state.requestsSelected = response;
                        context.state.emailType = 'provider'
                        context.state.isFormal = settings.emails.selectedIsFormal.provider;
                        resolve('provider');
                    } else {
                        context.state.emailType = 'client'
                        context.state.isFormal = settings.emails.selectedIsFormal.client;
                        context.state.requestsSelected = [];
                        resolve('client');
                    }
                })
            });
        },

        internalInitialEmailsSelected: function (context) {
            // Initial IN email selected once we have the list of emails
            if (context.state.emailsSelected.in.length === 0 && context.getters.emailsListIn.length > 0) {
                context.commit('internalInitialEmailsSelected', { direction: 'in', email: context.getters.emailsListIn[0] });
            }
            // Initial OUT email selected once we have the list of emails
            if (context.state.emailsSelected.out.length === 0 && context.getters.emailsListOut.length > 0) {
                context.commit('internalInitialEmailsSelected', { direction: 'out', email: context.getters.emailsListOut[0] });
            }
        },


        updateEmailsServer: function (context, list) {
            // Update the server list
            context.commit('updateEmailsServer', list);
            // Initial Email
            context.dispatch('internalInitialEmailsSelected');
        },


        updateEmailsLive: function (context, list) {
            // Update the live list
            context.commit('updateEmailsLive', list);
            // Initial Email
            context.dispatch('internalInitialEmailsSelected');
        },


        deleteEmailsSelected: function (context) {
            // Delete from List and Empty emailsSelected (for the current direction)
            context.commit('internalDeleteEmailsSelected');
            // Initial Email
            context.dispatch('internalInitialEmailsSelected');
        },
    }
});
