var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "email-attachments" },
        [
          _c("input", {
            ref: "fileUpload",
            staticClass: "is-hidden",
            attrs: { type: "file", multiple: "" },
            on: { input: _vm.addFiles },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-gray",
              staticStyle: { "margin-bottom": "5px" },
              on: {
                click: function ($event) {
                  return _vm.$refs.fileUpload.click()
                },
              },
            },
            [_vm._v("Anhänge vom Compuer hochladen")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-gray",
              staticStyle: { "margin-bottom": "5px" },
              on: {
                click: function ($event) {
                  _vm.isModalVisible = true
                },
              },
            },
            [_vm._v("Anhänge aus dem Vorgang hochladen")]
          ),
          _vm._v(" "),
          _vm.isModalVisible
            ? [
                _c(
                  "div",
                  {
                    staticClass: "panel-block",
                    staticStyle: { "background-color": "#F7F9FA" },
                  },
                  [
                    _c("div", { staticClass: "redesign-table" }, [
                      _c(
                        "div",
                        { staticClass: "table-wrapper" },
                        [
                          _c("filters", {
                            staticClass: "row is-filters",
                            attrs: { options: _vm.optionsDocuments },
                            model: {
                              value: _vm.documentFilter,
                              callback: function ($$v) {
                                _vm.documentFilter = $$v
                              },
                              expression: "documentFilter",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row is-head" }, [
                            _c("div", { staticClass: "cell is-user-cell" }, [
                              _c("input", {
                                attrs: { type: "checkbox" },
                                on: { input: _vm.toggleAll },
                              }),
                            ]),
                            _vm._v(" "),
                            _vm._m(0),
                            _vm._v(" "),
                            _vm._m(1),
                            _vm._v(" "),
                            _vm._m(2),
                            _vm._v(" "),
                            _vm._m(3),
                            _vm._v(" "),
                            _vm._m(4),
                          ]),
                          _vm._v(" "),
                          _vm.isLoading.documents
                            ? _c("loading")
                            : [
                                _vm.documents.length === 0
                                  ? _c("div", { staticClass: "is-empty" }, [
                                      _vm._v("Es gibt keine Dokumente"),
                                    ])
                                  : _c(
                                      "div",
                                      { staticClass: "is-zebra" },
                                      _vm._l(
                                        _vm.documents,
                                        function (document) {
                                          return _c(
                                            "div",
                                            {
                                              key: document.id,
                                              staticClass: "row",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-user-cell",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.checkedDocuments,
                                                        expression:
                                                          "checkedDocuments",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      value: document,
                                                      checked: Array.isArray(
                                                        _vm.checkedDocuments
                                                      )
                                                        ? _vm._i(
                                                            _vm.checkedDocuments,
                                                            document
                                                          ) > -1
                                                        : _vm.checkedDocuments,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.checkedDocuments,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = document,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.checkedDocuments =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.checkedDocuments =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.checkedDocuments =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-one-third",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(document.name) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-smaller-cell",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.fileType(document)
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-larger-cell",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "tags" },
                                                    [
                                                      document.type
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "tag is-white",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    _vm.getValueWithKey(
                                                                      {
                                                                        key: document.type,
                                                                        optionList:
                                                                          _vm
                                                                            .optionsDocuments
                                                                            .types,
                                                                      }
                                                                    )
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-days-cell",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "fullYearWithTime"
                                                        )(document.createdAt)
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-actions",
                                                },
                                                [
                                                  _vm.previewable(document)
                                                    ? _c(
                                                        "button",
                                                        {
                                                          attrs: {
                                                            title: "Vorschau",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.previewDocument(
                                                                document
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons",
                                                            },
                                                            [_vm._v("search")]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                              ],
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("footer", { staticStyle: { display: "block" } }, [
                  _c("div", { staticClass: "level" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-cancel",
                        on: {
                          click: function ($event) {
                            _vm.isModalVisible = false
                          },
                        },
                      },
                      [_vm._v("Abbrechen")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button is-primary",
                        on: { click: _vm.addAttachments },
                      },
                      [_vm._v("Hinzufügen")]
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "align-items": "flex-start",
              },
            },
            [
              _vm._l(_vm.localAttachments, function (file, index) {
                return [
                  _c("div", { staticClass: "level level-left mb-1" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button level-item",
                        attrs: { title: file.name },
                        on: {
                          click: function ($event) {
                            return _vm.previewDocument(file)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-file",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(
                          " " + _vm._s(file.name) + "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-delete level-item",
                        attrs: { title: "Löschen" },
                        on: {
                          click: function ($event) {
                            return _vm.localAttachments.splice(index, 1)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("delete"),
                        ]),
                      ]
                    ),
                  ]),
                ]
              }),
            ],
            2
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("preview", {
        model: {
          value: _vm.previewURL,
          callback: function ($$v) {
            _vm.previewURL = $$v
          },
          expression: "previewURL",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell is-one-third is-sortable" }, [
      _c("i", { staticClass: "fa fa-file", attrs: { "aria-hidden": "true" } }),
      _vm._v(
        "\n                                    Dokumentenname\n                                "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell is-smaller-cell" }, [
      _c("i", {
        staticClass: "fa fa-file-o",
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v(
        "\n                                    Dateityp\n                                "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell is-sortable is-larger-cell" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("help")]),
      _vm._v(
        "\n                                    Kategorie\n                                "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell is-sortable is-days-cell" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("date_range")]),
      _vm._v(
        "\n                                    Erstellungsdatum\n                                "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell is-actions" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("search")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }